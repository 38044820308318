import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import PostQuery from "./PostQuery"
import { Wrapper, Header } from "./styles"

const Blog = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(slug: { in: "blogi" }) {
        content
        title
      }
    }
  `)

  return (
    <Wrapper>
      {data && (
        <Header>
          <h2>{data.wpPage.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: data.wpPage.content }} />
        </Header>
      )}
      <PostQuery />
    </Wrapper>
  )
}

export default Blog
