import React from "react"
import { Data, Wrapper } from "./styles"
import Truncate from "react-truncate-html"

const PostCard = props => {
  const { title, slug, image, content, last, color } = props || {}
  return (
    <Wrapper last={last}>
      <a href={slug}>
        {image && <img src={image} />}
        <Data color={color[0]}>
          <h2>{title}</h2>
          <Truncate
            lines={3}
            portrait={4}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </Data>
      </a>
    </Wrapper>
  )
}

export default PostCard
