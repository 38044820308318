import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  margin-bottom: 6rem;
`

export const QueryWrapper = styled.div``

export const Categories = styled.div`
  display: none;
  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
  }
`

export const Posts = styled.div`
  @media only screen and (min-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

export const Button = styled.button`
  border: none;
  background-color: transparent;
  font-size: 14px;
  font-family: "Biennale", sans-serif;
  font-weight: 500;
  color: #3f4a59;
  outline: none;
  margin: 0 1rem;
  &:active {
    outline: none;
  }
  ${props =>
    props.selected === "selected" &&
    css`
      border-bottom: 1px solid #000;
    `}
`

export const Header = styled.div`
  margin: 3rem 0;
  h2 {
    font-size: 32px;
    font-weight: 700;
    color: #3f4a59;
  }
  p {
    font-size: 13px;
    color: #3f4a59;
  }
  @media only screen and (min-width: 1024px) {
    margin: 5rem 0;
    margin-bottom: 3rem;
    display: flex;
    p {
      font-size: 14px;
      line-height: 25px;
    }
    h2 {
      min-width: 350px;
      font-size: 52px;
    }
  }
`
