import React from "react"
import Blog from "../components/Blog"
import Layout from "../components/layout"
import Footer from "../components/Footer"
import Header from "../components/Header"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"

export default function Blogi({ data }) {
  const page = useStaticQuery(graphql`
    query {
      wpPage(slug: { in: "blogi" }) {
        title
        seo {
          metaDesc
          metaKeywords
        }
      }
    }
  `)


  return (
    <>
      <Header />
      <Layout>
        <SEO
          title={page.wpPage.title}
          description={page.wpPage.seo?.metaDesc}
        />
        <Blog />
      </Layout>
      <Footer />
    </>
  )
}
