import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  margin: 1rem 0;
  a {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: unset;
    background-color: #eaf2f3;
    justify-content: space-between;
    height: 100%;

    img {
      width: 100%;
      height: 360px;
      object-fit: cover;
      margin: 0;
    }

    @media only screen and (max-width: 575px) {
      img {
        max-height: 260px;
      }
    }
  }
  @media only screen and (min-width: 1024px) {
    flex: 0 0 49%;
    ${props =>
      props.last === "lastOdd" &&
      css`
        flex: 0 0 100%;
      `}
    a {
      overflow: hidden;
      img {
        transition: 0.25s ease;
        overflow: hidden;
      }
      &:hover {
        img {
          transition: 0.25s ease;
          transform: scale(1.1);
        }
      }
    }
  }
`

export const Data = styled.div`
  padding: 1.5rem 0.75rem;
  margin-top: auto;

  ${props =>
    props.color === "brown" &&
    css`
      background-color: #f7f2ea;
      h2 {
        color: #7b6244;
      }
      p {
        color: #7b6244;
      }
    `}
  ${props =>
    props.color === "teal" &&
    css`
      background-color: #eaf2f3;
      h2 {
        color: #3f4a59;
      }
      p {
        color: #3f4a59;
      }
    `}
  h2 {
    font-size: 26px;
    font-weight: 600;
  }
  p {
    font-size: 14px;
    font-weight: 500;
  }
  @media only screen and (min-width: 1024px) {
    padding: 1.5rem;
    h2 {
      font-size: 38px;
    }
    p {
      font-size: 14px;
    }
  }
`
