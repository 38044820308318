import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import PostCard from "../UI/PostCard"
import { Button, Categories, Posts, QueryWrapper } from "./styles"

export default () => {
  const [category, setCategory] = useState(null)

  const data = useStaticQuery(graphql`
    {
      allWpPost(sort: { order: DESC, fields: date }) {
        nodes {
          excerpt
          id
          slug
          title
          uri
          featuredImage {
            node {
              id
              sourceUrl
            }
          }
          terms {
            nodes {
              id
              slug
            }
          }
        }
      }
      allWpCategory {
        nodes {
          name
          id
          link
        }
      }
    }
  `)

  const total = data.allWpPost.nodes.length
  let i = 0

  return (
    <QueryWrapper>
      <Categories>
        <Button
          onClick={() => setCategory(null)}
          selected={category === null && "selected"}
        >
          Kõik
        </Button>
        {data &&
          data.allWpCategory.nodes.map(node => {
            return (
              <Button
                onClick={() => setCategory(node.id)}
                key={node.id}
                selected={category === node.id && "selected"}
              >
                {node.name}
              </Button>
            )
          })}
      </Categories>
      <Posts>
        {data &&
          data.allWpPost.nodes.map(node => {
            i++
            if (category) {
              return (
                category === node.terms.nodes[0].id && (
                  <PostCard
                    key={node.uri}
                    title={node.title}
                    slug={`${node.id}/${node.slug}`}
                    image={node.featuredImage?.node.sourceUrl}
                    content={node?.excerpt}
                    test={(i === total) & (i % 2 == 0) && "lastOdd"}
                    color={node.terms.nodes.map(col => {
                      console.log(col.slug)
                      if (col.slug === "saun") {
                        return "brown"
                      } else if (col.slug === "spa") {
                        return "teal"
                      }
                    })}
                  />
                )
              )
            } else {
              return (
                <PostCard
                  key={node.uri}
                  title={node.title}
                  slug={`${node.id}/${node.slug}`}
                  image={node.featuredImage?.node.sourceUrl}
                  content={node.excerpt}
                  last={(i === total) & (i % 2 !== 0) && "lastOdd"}
                  color={node.terms.nodes.map(col => {
                    if (col.slug === "saun") {
                      return "brown"
                    } else if (col.slug === "spa") {
                      return "teal"
                    }
                  })}
                />
              )
            }
          })}
      </Posts>
    </QueryWrapper>
  )
}
